export default defineNuxtPlugin(async () => {
  const { user, setUser } = useAuthStore()

  if (!user) {
    try {
      const data = await useApi('/me')
      setUser(data.value)
    } catch (error) {
      console.error('Error fetching user:', error)
    }
  }
})
