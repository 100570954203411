<template>
  <div class="text-gray-800 flex min-h-screen w-full flex-col justify-between">
    <Navigation />

    <main class="flex-1">
      <NuxtPage />
    </main>

    <AppFooter />

    <Breaky />
  </div>
</template>

<script setup>
const router = useRouter()
const { locale } = useI18n()
const localeRoute = useLocaleRoute()
const nuxtApp = useNuxtApp()
const { $patch } = useGlobalsStore()

// Fetch all navigations and globals
const { data } = await useCachedGql({
  operation: 'NavigationsAndGlobals',
  variables: {
    lang: locale.value,
  },
})

if (data.value) {
  $patch(data.value)
} else {
  console.error('Could not fetch navigation and globals in app.vue')
}

nuxtApp.$i18n.onLanguageSwitched = async (oldLocale, newLocale) => {
  const { data: newData } = await useCachedGql({
    operation: 'NavigationsAndGlobals',
    variables: {
      lang: newLocale,
    },
  })

  $patch(newData.value)
}

// -- MOUNTED --
onMounted(() => {
  window.addEventListener('keydown', navigateToSearchPage)
})

onUnmounted(() => {
  window.removeEventListener('keydown', navigateToSearchPage)
})

// -- METHODS --
function navigateToSearchPage(e) {
  if (e.key === 'k' && (e.ctrlKey || e.metaKey)) {
    return router.push(localeRoute('/search'))
  }
}
</script>
